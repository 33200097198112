import React from "react";
import "tailwindcss/tailwind.css";

const ProfileChairmanCard = () => {
  const profile = "/assets/profile.jpg";
  const Principal = "/assets/principal.jpg";

  return (
    <div className="p-6 bg-gray-100">
      {/* Title */}
      <h2 className="text-xl font-bold text-gray-900 mb-4 border-l-4 border-yellow-400 pl-2">
        Chairman & Principal
      </h2>
      {/* Cards Container */}
      <div className="flex flex-col lg:flex-row justify-center items-start gap-10">
        {/* Chairman Card */}
        <div className="bg-white border-2 border-yellow-400 rounded-lg p-8 w-[600px] h-[700px] flex flex-col items-center text-left">
          {/* Fixed Photo */}
          <div className="w-48 h-48 overflow-hidden border border-gray-400">
            <img src={profile} alt="Profile" className="object-cover w-full h-full" />
          </div>
          {/* Fixed Name & Designation */}
          <h2 className="text-lg font-bold text-gray-900 text-center mt-3">Dr M.V.V. Prasada Rao</h2>
          <h3 className="text-sm font-medium text-gray-600 text-center">(Chairman)</h3>
          {/* Scrollable Message */}
          <div className="overflow-y-auto h-[350px] w-full mt-3 pr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            <p className="text-sm text-gray-700 leading-relaxed text-left">
              Dr. M.V.V. Prasada Rao, Chairman of Dream Catchers, is a distinguished educationist and advisor with 37 years of extensive expertise in educational administration. A retired Director of CBSE, Dr. Rao holds an impressive academic portfolio, including an MA, MPhil, BL, B.Ed, and a Ph.D.
            </p>
            <p className="text-sm text-gray-700 mt-3 leading-relaxed text-left">
              Throughout his illustrious career, Dr. Rao has held prominent leadership roles such as Joint Secretary and Regional Officer for the Chennai, Allahabad, Patna, Bhubaneswar, and Delhi regions. He also served as the Regional Director of the UGC (NET) Examination. Notably, he was invited by the Ministry of Education, Government of Israel, to participate in a specialized training program on study planning and curriculum development in Jerusalem.
            </p>
            <p className="text-sm text-gray-700 mt-3 leading-relaxed text-left">
              In addition to his administrative achievements, Dr. Rao served as the District Program Coordinator for NSS, East Godavari, and dedicated over nine years as an NSS Program Officer, contributing significantly to community development and youth engagement.
            </p>
          </div>
        </div>

        {/* Principal Card */}
        <div className="bg-white border-2 border-yellow-400 rounded-lg p-8 w-[600px] h-[700px] flex flex-col items-center text-left">
          {/* Fixed Photo */}
          <div className="w-48 h-48 overflow-hidden border border-gray-400">
            <img src={Principal} alt="Profile" className="object-cover w-full h-full" />
          </div>
          {/* Fixed Name & Designation */}
          <h2 className="text-lg font-bold text-gray-900 text-center mt-3">Swati Bhalotia</h2>
          <h3 className="text-sm font-medium text-gray-600 text-center">(Principal In Charge)</h3>
          {/* Scrollable Message */}
          <div className="overflow-y-auto h-[350px] w-full mt-3 pr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
            <p className="text-sm text-gray-700 leading-relaxed text-left">
              Welcome to DREAM CATCHERS PLAY SCHOOL, where we believe that every child is unique and deserving of a joyful, stimulating, and supportive environment to begin their educational journey. We aim to instill a love for learning, creativity and social responsibility for their future academics and personal success.
            </p>
            <p className="text-sm text-gray-700 mt-3 leading-relaxed text-left">
              At our play school, we focus on developing not only academic abilities but also emotional intelligence, critical thinking, and a love for discovery. Through interactive play, storytelling, and exploration, we foster a sense of confidence and independence in each child.
            </p>
            <p className="text-sm text-gray-700 mt-3 leading-relaxed text-left">
              We are committed to providing a safe, inclusive, and caring space for children to thrive. With the support of dedicated teachers and staff, we aim to help every child reach their full potential, ensuring that their first steps in education are both joyful and meaningful.
            </p>
            <p className="text-sm text-gray-700 mt-3 leading-relaxed text-left">
              Thank you for choosing us as a part of your child’s early development. Together, we’ll build a bright future for our little learners!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileChairmanCard;
